<template>
  <div class="banner" id="card-container">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">Minimum size</h2>
          <div class="tip">
            · Minimum use of brand logos on print media: height greater than or
            equal to 12mm
          </div>
          <div class="tip">
            · The minimum use of the brand logo on online media: the height is
            greater than or equal to 40px
          </div>
        </div>
        <div class="banner__right_container">
          <div class="banner__right">
            <img src="@/assets/brand-mini.svg" class="inst_card_m" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpaceAndSize',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin: 80px 0;
  @media (max-width: 1080px) {
    height: auto;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  align-items: center;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__left {
  max-width: 600px;
  margin-right: 150px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    padding: 24px 8px;
  }
}

.banner__right_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  .inst_card_m {
    display: block;
    height: 60px;
    @media (max-width: 1080px) {
      width: 80%;
      height: auto;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 48px;
    justify-content: center;
  }
}

@keyframes inst_card_animation {
  0% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.6);
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 48px;
  line-height: 50px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  margin-bottom: 30px;
}

.l-card-table {
  margin-top: 200px;
}
</style>
