<template>
  <div class="homeTop">
    <div class="homeTopContainer">
      <div class="topContainer">
        <h2 class="g-title title ml2">Inst branding</h2>
        <div class="list-details-container">
          <div class="list-details">
            <div class="details">
              <span>
                See below official Inst logos and branding assets which can be
                downloaded.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeTop',
};
</script>

<style lang="scss" scoped>
.homeTop {
  background: #0045ff;
  width: 100%;
  padding: 80px 0 0;
  background-size: 100% 100%;
  height: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
  @media (max-width: 960px) {
    background: #0045ff;
    height: 640px;
  }
  .top_down_icon {
    position: absolute;
    bottom: 64px;
    @media (max-width: 960px) {
      display: none;
    }
    img {
      width: 32px;
    }
  }
  .homeTopContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background: url("~@/assets/brand_logo_blue.svg") center center no-repeat;
    background-size: 1016px 400px;
    padding: 100px 0;
    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    .topContainer {
      display: flex;
      flex-direction: column;
      @media (max-width: 960px) {
        margin-right: 0;
        width: 98%;
        text-align: center;
      }
      .ml2 {
        font-weight: 900;
        font-size: 3.5em;
      }
      .ml2 .letter {
        display: inline-block;
        line-height: 1em;
      }
      .title {
        color: #ffffff;
        font-family: "Poppins-Regular";
        font-style: normal;
        line-height: 81px;
        font-weight: 700;
        font-size: 60px;
        text-align: center;
        @media (max-width: 960px) {
          padding: 20px 8px;
          font-size: 60px;
        }
      }
      .topTips {
        font-family: Poppins-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.01em;
        opacity: 0.4;
        color: #ffffff;
      }
      .list-details-container {
        margin-top: 20px;
        color: #ffffff;
        .list-details {
          display: flex;
          margin-bottom: 30px;
          img {
            margin-right: 20px;
          }
          .details {
            font-weight: 400;
            font-size: 20px;
            @media (max-width: 960px) {
              text-align: left;
            }
            span {
              font-weight: 400;
              font-size: 24px;
              line-height: 48px;
              text-align: center;
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
        .choose-payment-img {
          margin-top: 40px;
        }
      }
      .button-container {
        display: flex;
        margin-top: 60px;
        .button-w {
          background: #ffffff;
          color: #0045ff;
          border-radius: 8px;
          margin-right: 20px;
          width: 200px;
          height: 60px;
          font-weight: 500;
          font-size: 20px;
          @media (max-width: 960px) {
            flex: 1;
            width: auto;
          }
        }
        .button-b {
          color: #ffffff;
          background: #0045ff;
          border-radius: 8px;
          border: 2px solid #ffffff;
          width: 200px;
          height: 60px;
          font-weight: 500;
          font-size: 20px;
          @media (max-width: 960px) {
            flex: 1;
            width: auto;
          }
        }
      }
    }
    .topContainerRight {
      border-radius: 10px;
      overflow: hidden;
      // max-width: 360px;
      width: 480px;
      height: 600px;
      display: flex;
      margin-right: 100px;
      @media (max-width: 960px) {
        margin: 20px 0;
        width: 90%;
        img {
          width: 100%;
        }
      }
    }
  }
  .topBottom {
    display: flex;
    max-width: 1280px;
    margin: 60px auto 0;
    @media (max-width: 960px) {
      flex-direction: column;
    }
    .topBottomList {
      flex: 1;
      text-align: center;
      .topBottomListTips {
        margin-top: 20px;
        font-family: Poppins-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 50px;
        letter-spacing: 0.01em;
        color: #ffffff;
        opacity: 0.8;
      }
    }
  }
}
</style>
