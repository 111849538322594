<template>
  <div class="banner" id="card-container">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">Logo</h2>
          <div class="tip">
            The component includes the symbol mark and logomark.
            <strong>Using the correct artwork</strong> The logo should not be
            recreated or used in any other form or arrangement. All artwork
            should use the logo at the correct sizes and in the correct
            position. If our name is written in plain text, do not mimic the
            styling of the logo. Write our name on one line, like this: Inst.
          </div>
        </div>
        <div class="banner__right_container">
          <div class="banner__right">
            <img src="@/assets/brand-logo.svg" class="inst_card_m" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncorrectApplication',
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  margin: 80px 0;
  @media (max-width: 1080px) {
    height: auto;
  }
}

.wrapper {
  position: relative;
  padding: 0;
  @media (max-width: 1080px) {
    width: 100%;
  }
}

.top {
  display: flex;
  width: 1280px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.banner__left {
  max-width: 600px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 24px 8px;
  }
}

.banner__right_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  .inst_card_m {
    display: block;
    height: 200px;
    @media (max-width: 1080px) {
      width: 80%;
      height: auto;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 48px;
    justify-content: center;
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 48px;
  color: #0045ff;
  line-height: 50px;
  @media (max-width: 1080px) {
    font-size: 30px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  margin-bottom: 30px;
  strong {
    color: #000;
  }
}

.l-card-table {
  margin-top: 200px;
}
</style>
