<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <HomeTop />
      <LogoContainer />
      <SpaceAndSize />
      <MinimumSize />
      <CorrectApplication />
      <IncorrectApplication />
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import HomeTop from '@/views/Brand/HomeTop.vue';
import LogoContainer from '@/views/Brand/LogoContainer.vue';
import SpaceAndSize from '@/views/Brand/SpaceAndSize.vue';
import MinimumSize from '@/views/Brand/MinimumSize.vue';
import CorrectApplication from '@/views/Brand/CorrectApplication.vue';
import IncorrectApplication from '@/views/Brand/IncorrectApplication.vue';

import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'Home',
  components: {
    NavHeader,
    NavHeaderMobile,
    HomeTop,
    LogoContainer,
    SpaceAndSize,
    MinimumSize,
    CorrectApplication,
    IncorrectApplication,
    NewFooter,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #ffffff;
}
</style>
